@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';

.stickyWrapper {
  position: sticky;
  top: 0;
}

.background {
  position: absolute;
  width: 100%;
  padding-top: 85.33%; // 1/(375/320)
  overflow: hidden;

  @include breakpoints.media(mobile-landscape) {
    padding-top: 56.25%; // 1/(16/9)
  }

  &Blurred {
    opacity: 0.4;
  }

  &InPackageView {
    opacity: 0.7;

    &.backgroundWithoutImage {
      background-color: $yds-color-feedback-highlight;
      transition: background-color 400ms;
    }
  }
}

/* Left to right fade */
.gradient1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background: linear-gradient(
    259.21deg,
    rgba($yds-color-background, 0) 45.18%,
    rgba($yds-color-background, 0.8) 76.54%
  );

  .backgroundInPackageView & {
    display: none;
  }
}

/* Top to bottom fade */
.gradient2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 29.86%;
  bottom: 0;

  background: linear-gradient(
    180deg,
    rgba($yds-color-background, 0) 0%,
    $yds-color-background 57.2%
  );

  .backgroundInPackageView & {
    top: 0;
  }
}

.image {
  object-fit: cover;
  object-position: center top;
  transition: opacity 400ms;
}

.imageHidden {
  opacity: 0;
}
