@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.root {
  @include typography.default-l-bold;
  margin: 0;
  margin-bottom: spacing.$spacing-24;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: spacing.$spacing-24;
  row-gap: spacing.$spacing-8;

  @include breakpoints.media(tablet) {
    @include typography.default-xl-bold;
  }

  @include breakpoints.media(desktop) {
    margin-bottom: spacing.$spacing-40;
  }

  li {
    list-style: none;
  }
}

.link {
  border-bottom: 4px solid transparent;
  padding-top: spacing.$spacing-8;
  padding-bottom: spacing.$spacing-8;
  text-decoration: none;

  &Active {
    border-bottom-color: $yds-color-feedback-highlight;
  }
}
